import React, { Component } from 'react'
 class UstoMeeting extends React.Component{
    render(){
        return(
            <div>
                <p>UstoMeeting</p>
            </div>
        );
    }
 }

 export default UstoMeeting; 