import React, { Component } from 'react'
 class Regesitrarion extends React.Component{
    render(){
        return(
            <div>
                <p>Registration</p>
            </div>
        );
    }
 }

 export default Regesitrarion; 