import React, { Component } from 'react'
 class UstoTalent extends React.Component{
    render(){
        return(
            <div>
                <p>UstoTalent</p>
            </div>
        );
    }
 }

 export default UstoTalent; 